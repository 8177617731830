import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { PrivacyPolicyLink, TermsOfUseLink } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { ReactNode, useState } from 'react';

export default function SkipOption({
  leadingContent,
  onCancel,
  onConfirm,
}: Readonly<{
  leadingContent?: ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
}>) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>More options &gt;</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>More Options</DialogTitle>
        <DialogContent>
          <Stack gap={tokens.spacingXs}>
            {leadingContent && leadingContent}
            <Typography variant="body2">
              The VerifiMe team recommends creating a wallet for enhanced convenience and security.
            </Typography>
            <Typography variant="body2">
              With a VerifiMe wallet, you&apos;ll save time, track where you&apos;ve shared it, and
              securely access your identity.
            </Typography>
            <Typography variant="caption" mt={tokens.spacingLg}>
              By proceeding, you agree to our <TermsOfUseLink /> and <PrivacyPolicyLink />
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              onCancel?.();
            }}
          >
            Go Back to Create Wallet
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              onConfirm?.();
            }}
          >
            Proceed without a wallet
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
