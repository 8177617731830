import { Button, Divider, Stack, Typography } from '@mui/material';
import { Alert, PrivacyPolicyLink, TermsOfUseLink } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import SkipOption from './SkipOption';

export default function CreateYourVerifimeWallet({
  displayName,
  showSkipOption = false,
  onCreateWalletClicked,
  onSignInAsADifferentUserClicked,
  onSkipOptionConfirmed,
}: {
  displayName: string;
  showSkipOption?: boolean;
  onCreateWalletClicked: () => void;
  onSignInAsADifferentUserClicked: () => void;
  onSkipOptionConfirmed?: () => void;
}) {
  return (
    <Stack maxWidth={tokens.sizeXs} gap={tokens.spacingLg} alignItems="center">
      <Typography variant="body1">Create wallet using</Typography>
      <Typography variant="h6" sx={{ wordBreak: 'break-all', textAlign: 'center' }}>
        {displayName}
      </Typography>
      <Alert severity="info">
        Make sure you have two forms of identification ready to go. This could be your passport,
        driver&apos;s licence or Medicare card.
      </Alert>
      <Button fullWidth variant="contained" onClick={onCreateWalletClicked}>
        Create your verifime wallet
      </Button>
      <Typography variant="caption">
        By continuing, you agree to our <TermsOfUseLink /> and <PrivacyPolicyLink />
      </Typography>
      <Divider sx={{ width: '100%' }} />
      <Button fullWidth variant="text" onClick={onSignInAsADifferentUserClicked}>
        Sign in as a different user
      </Button>
      {showSkipOption && <SkipOption onConfirm={onSkipOptionConfirmed} />}
    </Stack>
  );
}
